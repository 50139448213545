<template>
  <a
    href="https://www.youtube.com/channel/UCnSVxvzLluu7Ij02E3wDq-A"
    target="_blank"
    rel="noopener"
  >
    <img
      src="@/assets/img/social/youtube-logo.svg"
      alt="Youtube social media link"
      loading="lazy"
      width="32"
      height="32"
    />
  </a>
</template>
