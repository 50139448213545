<template>
  <a href="https://twitter.com/TweetDeleter" target="_blank" rel="noopener">
    <img
      src="@/assets/img/social/twitter-logo-gray.svg"
      alt="Twitter social media link"
      loading="lazy"
      width="32"
      height="32"
    />
  </a>
</template>
