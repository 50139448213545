<template>
  <a
    href="https://www.facebook.com/TweetDeleter/"
    target="_blank"
    rel="noopener"
  >
    <img
      src="@/assets/img/social/facebook-logo.svg"
      alt="Facebook social media link"
      loading="lazy"
      width="32"
      height="32"
    />
  </a>
</template>
